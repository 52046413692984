<template>
  <!-- <client-only> -->
  <Swiper
    :pagination="{
      clickable: true,
    }"
    :autoplay="sliderAutoplay"
    :modules="modules"
    aria-label="My Favorite Images"
    :slides-per-view="1"
    :space-between="5"
    class="h-auto lg:h-[720px]"
    :class="pageClass"
  >
    <SwiperSlide v-for="item in data?.slides" :key="item.id">
      <!-- <NuxtLink
        class="flex h-full flex-col"
        :class="{ 'flex-col-reverse': data.bottomMobile }"
        :to="item?.buttonUrl"
      > -->
      <div
        class="flex h-full flex-col"
        :class="{ 'flex-col-reverse': data.bottomMobile }"
        @click="onClick(item?.buttonUrl)"
      >
        <div
          class="cursor-pointer h-full max-lg:max-h-[410px]"
          :class="{'max-lg:max-h-[610px]': data.overlayForMobile}"
          :style="
            brightnessFilter(item)
              ? { filter: `brightness(${brightnessFilter(item)}%)` }
              : null
          "
        >
          <UiLazyImg
            class="max-lg:h-[410px] h-full object-center"
            :class="{'max-lg:max-h-[610px] max-lg:h-[610px]': data.overlayForMobile}"
            :src="item?.file?.url || ''"
            :src-mobile="item?.fileMobile?.url || ''"
            :alt="
              item?.file?.alt ||
              item.title ||
              removeHTMLTagFromString(item.text) ||
              'Image'
            "
            cover
            :no-lazy="!blockIndex"
          />
        </div>
        <div
          class="w-full h-fit"
          :style="[
            lgAndDown
              ? {
                  backgroundColor:
                    item?.backgroundColorMobile?.hexa ||
                    item?.backgroundColor?.hexa ||
                    '#F4EEE8',
                }
              : {},
          ]"
        >
          <div
            class="container inset-0 flex h-full items-center justify-center py-15 lg:absolute lg:justify-end lg:py-4"
            :class="{'max-lg:absolute max-lg:py-4': data.overlayForMobile}"
          >
            <div class="w-full" @click="contentLink" v-html="item.text" />
          </div>
        </div>
      </div>
      <!-- </NuxtLink> -->
    </SwiperSlide>
  </Swiper>
  <!-- </client-only> -->
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper";
import { BlockSliderContent } from "ecom-types";
import "swiper/css/pagination";
import { useAppBreakpoints } from "~/composables";

const route = useRoute();
const router = useRouter();
const localePath = useLocalePathPolyfill();
const contentLink = useContentLink;
const props = withDefaults(
  defineProps<{
    data: BlockSliderContent;
    blockIndex?: number;
  }>(),
  {
    blockIndex: -1,
  }
);

const modules = [Pagination, Autoplay];

const { lgAndDown, lgAndUp } = useAppBreakpoints();
const { brightnessFilter } = useBrightnessFilter(lgAndUp);

const pageClass = computed(() => {
  const slug = route.path.slice(1)?.split("/")?.join("-") || "home";
  return slug + "-" + props.blockIndex;
});

const sliderAutoplay = computed(() => {
  return pageClass.value === "home-0"
    ? {
      delay: 5000,
      disableOnInteraction: false,
    }
    : false;
});

function onClick(url?: string) {
  if (url) {
    router.push(localePath(url));
  }
}
</script>

<style scoped>
.swiper :deep(.swiper-pagination) {
  @apply absolute bottom-2.5
}
.swiper :deep(.swiper-pagination-bullet) {
  @apply h-[13px] w-[13px];
}
.swiper :deep(.swiper-pagination-bullet) {
  @apply bg-neutral-white-100 h-[13px] w-[13px] opacity-100;
}
.swiper :deep(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
  @apply bg-dark-blue;
}

.swiper.home-0 :deep(.swiper-pagination) {
  @apply absolute bottom-auto top-[96vw] -translate-y-full lg:bottom-[30px] lg:top-auto lg:translate-y-0;
}
.swiper.home-0 :deep(.swiper-pagination-bullet) {
  @apply h-2 w-2 bg-gray-300 opacity-100;
}
.swiper.home-0
:deep(.swiper-pagination-bullet.swiper-pagination-bullet-active) {
  @apply bg-primary;
}
</style>
