<template>
  <div class="flex flex-col space-y-3">
    <label v-if="label" :for="internalId">
      <slot name="label">
        <span class="font-medium">{{ label }}</span>
      </slot>
    </label>
    <div class="flex items-center gap-0.5">
      <slot name="decrement">
        <UiButton
        variant="clear"
          :disabled="decrementDisabled || disabled"
          class="inline-flex size-6 shrink-0 items-center justify-center rounded-full border border-neutral-black-200 p-0 text-primary disabled:opacity-50"
          :aria-label="$t('decrementValue')"
          type="button"
          @click="decrement"
        >
          <UiIcon name="minus" class="size-3" />
        </UiButton>
      </slot>
      <input
        :id="internalId"
        v-model.number="currentValue"
        type="number"
        :disabled="disabled"
        :min="min"
        :max="max"
        :step="step"
        :class="
          cn(
            'h-6 w-full bg-white text-base font-normal text-black placeholder-primary placeholder-opacity-50 focus:outline-none',
            inputClasses,
          )
        "
        @blur="updateValue(Number(($event.target as HTMLInputElement).value))"
        @keydown.esc="
          updateValue(Number(($event.target as HTMLInputElement).value))
        "
        @keydown.enter="
          updateValue(Number(($event.target as HTMLInputElement).value))
        "
        @keydown.up.prevent="increment"
        @keydown.down.prevent="decrement"
      />
      <UiButton
      variant="clear"
        :disabled="incrementDisabled || disabled"
        class="inline-flex size-6 shrink-0 items-center justify-center rounded-full border border-neutral-black-200 p-0 text-primary disabled:opacity-50"
        :aria-label="$t('incrementValue')"
        type="button"
        @click="increment"
      >
        <UiIcon name="plus" class="size-4" />
      </UiButton>
    </div>
    <SharedFieldHint
      v-if="!hideDetails"
      :error="Boolean(errorMessage)"
      :message="errorMessage || ''"
    />
  </div>
</template>

<script setup lang="ts">
import { useField } from "vee-validate";
const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    max?: number | string;
    min?: number | string;
    modelValue: number | string;
    step?: number | string;
    precision?: number;
    inputClasses?: string | any[] | object;
    name?: string;
    lazy?: boolean;
    hideDetails?: boolean;
    label?: string;
  }>(),
  {
    max: Infinity,
    min: -Infinity,
    step: 1,
    precision: 0,
    inputClasses: "",
    name: "",
    lazy: true,
    hideDetails: false,
    label: "",
  },
);

const emit = defineEmits(["update:modelValue"]);

const internalId = useId();
const name = toRef(props, "name");
const { errorMessage, handleChange, handleBlur, meta, errors } = useField(
    name,
    undefined,
    {
      initialValue: props.modelValue,
    },
);

const currentValue = ref(Number(props.modelValue));

function myHandleBlur(e) {
  handleChange(e);
  handleBlur(e);
}

const decrementDisabled = ref(false);
const incrementDisabled = ref(false);

const minNumber = computed(() => {
  return Number(props.min);
});

const maxNumber = computed(() => {
  return Number(props.max);
});

const stepNumber = computed(() => {
  return Number(props.step);
});

watchEffect(() => {
  decrementDisabled.value = Number(currentValue.value) === minNumber.value;
  incrementDisabled.value = Number(currentValue.value) === maxNumber.value;
});

watch(
  () => props.modelValue,
  (val) => {
    currentValue.value = Number(val);
  },
);

function increment() {
  if (props.disabled || incrementDisabled.value) {
    return;
  }

  const newVal = currentValue.value + stepNumber.value;

  updateValue(Number(newVal.toFixed(props.precision)));
}
function decrement() {
  if (props.disabled || decrementDisabled.value) {
    return;
  }

  const newVal = currentValue.value + -1 * stepNumber.value;

  updateValue(Number(newVal.toFixed(props.precision)));
}
function updateValue(newVal: number) {
  if (newVal <= minNumber.value) {
    newVal = minNumber.value;
  }
  if (newVal >= maxNumber.value) {
    newVal = maxNumber.value;
  }
  currentValue.value = Number(newVal.toFixed(props.precision));
  if (props.modelValue !== currentValue.value) {
    emit("update:modelValue", currentValue.value);
    myHandleBlur(currentValue.value);
  }
}

defineExpose({
  meta,
  value: props.modelValue,
  name: props.name,
});
</script>
